@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
  align-self: center;
}

#map,
#recommended_map,
#comparisonWidgets {
  z-index: 1;
}

.marker-label {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
.spike-container {
  position: relative;
  width: 0;
  height: 0;
}

.spike {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid red; /* Solid color for the border */
  opacity: 0.5; /* Set the opacity for the entire spike */
}

.spike::after {
  content: "";
  position: absolute;
  top: 0;
  left: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid red; /* Solid color for the inside */
  opacity: 1; /* Set the opacity for the inside of the spike */
}

.custom-popup .leaflet-popup-content {
  margin: 8px 12px;
}

.custom-popup {
  left: -20px !important;
  margin-bottom: 10px !important;
}
.leaflet-bottom {
  z-index: 1;
}

.custom-popup .code {
  font-size: 14px;
  margin: 0;
  line-height: 16px;
  font-weight: 600;
}

.custom-popup .custom-popup-content {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
}

.leaflet-popup-content-wrapper {
  background: var(--neutral-background-base) !important;
  border-radius: 6px !important;
  position: relative;
}
.leaflet-popup-tip-container {
  display: none;
}
.leaflet-popup-content-wrapper::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid var(--neutral-background-base) !important;
  top: 100%;
  left: 20px;
  transform: translateX(-50%) rotateX(180deg);
}
.leaflet-popup-content-wrapper::before {
  transform: translateX(-50%) rotateX(180deg);
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 10px solid black;
  position: absolute;
  opacity: 0.1;
  content: "";
  height: 0;
  left: 20px;
  top: 100%;
  width: 0;
}

.custom-popup-content .members {
  border-bottom: 1px dashed #5f6877;
}

.custom-popup .members {
  margin-top: 8px;
}

.members,
.year {
  display: flex;
  justify-content: space-between;
  margin: 2px 0;
}

.custom-popup .year {
  margin-top: 4px;
}

.custom-popup .members,
.custom-popup .year {
  display: flex;
  flex-direction: row;
}

.custom-popup .custom-popup-label {
  color: #5f6877;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  margin: 0;
}

.custom-popup .custom-popup-value {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  margin: 0;
}

.custom-popup .leaflet-popup-tip-container {
  margin-left: 0;
  left: 0;
}

.leaflet-popup {
  position: absolute;
  text-align: center;
}
.dropdown-heading {
  height: 38px !important;
}

.sankey-line {
  border-top: 1px solid #939ba7;
  fill: #5f6877;
}

.leftAxisLine {
  stroke: #ccc;
  stroke-dasharray: 1 2;
}

.interactivity input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
}

.interactivity input[type="checkbox"]::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #5f6877;
  background-color: #d3dbe3;
  border-radius: 3px;
  margin-right: 8px;
  vertical-align: middle;
}

.interactivity input[type="checkbox"]:checked::before {
  background-color: #5f6877;
  content: "\2713";
  text-align: center;
  font-size: 12px;
  line-height: 16px;
}

.interactivity input[type="checkbox"] {
  color: #fff;
}

.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-attrib-inner {
  display: none;
  opacity: 0;
}

.mapboxgl-popup .mapboxgl-popup-tip {
  border-top-color: #1b1c20 !important;
}

.mapboxgl-popup {
  top: -10px;
}

.mapboxgl-popup .mapboxgl-popup-content {
  padding: 4px 7px;
  background: #2b2d37 !important;
  border-radius: 20px;
  border: 2px solid #1b1c20;
  font-size: 11px;
}

#map,
#recommended_map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.mapboxgl-ctrl-group button + button {
  border-color: #2b2d37;
}
.mapboxgl-ctrl button {
  background: #1b1c20;
}

.mapboxgl-ctrl button:hover {
  background: #222327 !important;
}

.mapboxgl-canvas {
  height: 100% !important;
}

.Toastify {
  z-index: 9999;
}

.w-color-swatch {
  gap: 1px;
}

.w-color-compact > div:nth-child(2) {
  display: none !important;
}
